import { call, cancelled, put, takeLatest, select } from 'redux-saga/effects';
import { Actions as ExportActions, Types as ExportTypes } from './export.redux';
import { globalFiltersUrlParamsSelector } from '../../modules/root/global-filters/global-filters.selectors'
import { localFiltersUrlParamsSelector } from './export.selectors'
import { getProducts, download } from './export.api';

function* getDataRequest() {
  try {
    const response = yield call(getProducts);
    const data = response.data;
    yield put(ExportActions.getDataSuccess(data));
  } catch (e) {
    yield put(ExportActions.getDataError(e));
  } finally {
    if (yield cancelled()) {
      yield put(ExportActions.getDataError(null));
    }
  }
}

function* downloadRequest({ key }) {
  try {
    let filters = ``;
    const globalFilters = yield select(globalFiltersUrlParamsSelector);

    const localFilters = yield select(localFiltersUrlParamsSelector);
    filters = `${globalFilters}&${localFilters}`;
    
    if (key === 'touchpoints' || key === 'lamps') {
      filters = '';
    } else if (key === 'all-equipments') {
      key = 'equipments';
      filters = '';
    }
    
    const response = yield call(download, key, filters);
    const data = response.data;
    yield put(ExportActions.downloadSuccess(data, key));
  } catch (e) {
    yield put(ExportActions.downloadError(e));
  } finally {
    if (yield cancelled()) {
      yield put(ExportActions.downloadError(null));
    }
  }
}

function* downloadSuccess({ data, key }) {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${key}.xlsx`);
  document.body.appendChild(link);
  link.click();
}

export default function* exportSaga() {
  yield takeLatest(ExportTypes.GET_DATA_REQUEST, getDataRequest);
  yield takeLatest(ExportTypes.DOWNLOAD_REQUEST, downloadRequest);
  yield takeLatest(ExportTypes.DOWNLOAD_SUCCESS, downloadSuccess);
}
