import { call, cancelled, put, takeLatest, select, all } from 'redux-saga/effects';
import {RoutingInjectionTypes, RoutingInjectionActions} from './routing-injection.redux';
import { getMissions, getAgents, getTouchPoints, postRouting, deleteMission, getTradePrograms, getInvolvements } from './routing-injection.api';
import {globalFiltersRoutingUrlParamsSelector} from '../../modules/root/global-filters/global-filters.selectors';
import {selectedAgentUsernameSelector, localFiltersUrlParamsSelector, touchPointsSelector} from './routing-injection.selectors';
import { dateSelector, selectedAgentSelector, selectedTouchPointsSelector, selectedTasksSelector } from './routing-injection.selectors';

function* getDataRequest() {
    try {
        const globalFilters = yield select(globalFiltersRoutingUrlParamsSelector);
        const localFilters = yield select(localFiltersUrlParamsSelector);
        const [data, agents, tradePrograms, involvements] = yield all([
            call(getMissions, `${globalFilters}`),
            call(getAgents, `${localFilters}`),
            call(getTradePrograms),
            call(getInvolvements)
            //call(getTouchPoints, `${globalFilters}`)
        ]);
        yield put(RoutingInjectionActions.getDataSuccess(data.data, agents.data, tradePrograms.data.programs, involvements.data));
    } catch (e) {
        yield put(RoutingInjectionActions.getDataError(e));
    } finally {
        if (yield cancelled()) {
            yield put(RoutingInjectionActions.getDataError(null));
        }
    }
}

function* selectAgent({ region, district, territory }) {
    try {
        let filters = `region=${region}`;
        if (district)
            filters += `&district=${district}`;
        if (territory)
            filters += `&territory=${territory}`;

        const date = yield select(dateSelector);
        const agent = yield select(selectedAgentUsernameSelector);

        const [response1, response2] = yield all([
            call(getTouchPoints, filters),
            call(getMissions, `date=${date}&agent=${agent}`),
        ]);
        const data1 = response1.data;
        const data2 = response2.data;
        yield put(RoutingInjectionActions.getTouchPointsSuccess(data1, data2));
    } catch (e) {
        yield put(RoutingInjectionActions.getTouchPointsError(e));
    } finally {
        if (yield cancelled()) {
            yield put(RoutingInjectionActions.getTouchPointsError(null));
        }
    }
}

function* addMission() {
    try {
        const date = yield select(dateSelector);
        const agent = yield select(selectedAgentSelector);
        const touchpoints = yield select(selectedTouchPointsSelector);
        const tasks = yield select(selectedTasksSelector);
        let params = {
            date, agent, touchpoints, tasks
        };
        const response = yield call(postRouting, params);
        const data = response.data;
        yield put(RoutingInjectionActions.addMissionSuccess(data));
    } catch (e) {
        yield put(RoutingInjectionActions.addMissionError(e));
    } finally {
        if (yield cancelled()) {
            yield put(RoutingInjectionActions.addMissionError(null));
        }
    }
}

function* deleteMissionRequest({ id }) {
    try {
        const response = yield call(deleteMission, id);
        const data = response.data;
        yield put(RoutingInjectionActions.deleteMissionSuccess());
    } catch (e) {
        yield put(RoutingInjectionActions.deleteMissionError(e));
    } finally {
        if (yield cancelled()) {
            yield put(RoutingInjectionActions.deleteMissionError(null));
        }
    }
}

function* actionFilter() {
    yield put(RoutingInjectionActions.getDataRequest());
}

export default function* routingInjectionSaga() {
    yield takeLatest(RoutingInjectionTypes.GET_DATA_REQUEST, getDataRequest);
    yield takeLatest(RoutingInjectionTypes.SELECT_AGENT, selectAgent);
    yield takeLatest(RoutingInjectionTypes.ADD_MISSION_REQUEST, addMission);
    yield takeLatest(RoutingInjectionTypes.DELETE_MISSION_REQUEST, deleteMissionRequest);
    yield takeLatest(RoutingInjectionTypes.ACTION_FILTER, actionFilter);

}