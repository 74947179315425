import { createSelector } from 'reselect';

export const dataSelector = state => state.get('export').get('data');

export const filtersSelector = state => state.get('export').get('filters');

export const selectedSelector = state => state.get('export').get('selected');

export const localFiltersUrlParamsSelector = createSelector(
  selectedSelector,
  filtersSelector,
  (selected, _filters) => {
    
    let productsFilter = 'products=';
    selected.get('products').map(value =>
      productsFilter += value + ','
    );
    if (productsFilter.endsWith(','))
      productsFilter = productsFilter.slice(0, -1);
    if (productsFilter.endsWith('products='))
      productsFilter = '';
      
    let rolesFilter = 'role=' + selected.get('role').entrySeq().map( ([key, value]) => value).toArray().join(',');

    if (rolesFilter == 'role=')
      rolesFilter = '';
      
      
    let fieldsFilter = 'exclude_fields=';

    _filters.get('fields').entrySeq()
      .map( ([key, value]) => key)
      .filter(f => !selected.get('fields').contains(f))
      .toArray()
      .map(value =>
        fieldsFilter += value + ','
      );

    if (fieldsFilter.endsWith(','))
      fieldsFilter = fieldsFilter.slice(0, -1);
    if (fieldsFilter.endsWith('exclude_fields='))
      fieldsFilter = '';

    return `${productsFilter}&${fieldsFilter}&${rolesFilter}`;
  }
);
