import axios from 'axios';
import Cookies from 'js-cookie';

export const api = axios.create({
  baseURL: window.location.origin+'/dash', // 'https://qa.ireport3.com/dash' // URL FIND ME
  headers: localStorage.getItem('token') ? {
    'Authorization': `Token ${localStorage.getItem('token')}`, 'X-CSRFToken': Cookies.get('csrftoken')
  } : {  'X-CSRFToken': Cookies.get('csrftoken') }
});

export const setAuthorization = () => {
  const token = localStorage.getItem('token');
  api.defaults.headers.common['Authorization'] = `Token ${token}`;
};
