import axios from 'axios';
import { api } from '../../services/api';

const PRODUCTS_URL = '/products/';

export const getProducts = () =>
  api.get(`${PRODUCTS_URL}`);

export const download = (key, filters) => axios({
  url: window.location.origin+`/dash/export/${key}/?${filters}`,  // https://qa.ireport3.com // URL FIND ME
  method: 'GET',
  responseType: 'blob',
  headers: localStorage.getItem('token') ? {
    'Authorization': `Token ${localStorage.getItem('token')}`
  } : null
});
