import { createActions, createReducer } from 'reduxsauce';
import { fromJS, Record, Map } from 'immutable';

export const { Types, Creators } = createActions({
  getDataRequest: [],
  getDataSuccess: ['data'],
  getDataError: ['e'],
  selectFilter: ['key', 'value'],
  unselectFilter: ['key', 'value'],
  selectTmo: ['value'],
  unselectTmo: ['value'],
  downloadRequest:['key'],
  downloadSuccess:['data', 'key'],
  downloadError:['e']
}, {
  prefix: 'EXPORT_'
});

const state = new Record({
  data: null,
  filters: null,
  selected: null
});

const INITIAL_STATE = new state({
  data: null,
  filters: fromJS({
    role: ['SFF', 'CFF', 'LRA', 'DS'],
    fields: Map({
      handling: "HANDLING",
      availability: "AVAILABILITY",
      stocks: "STOCK",
      price: "SELL PRICE",
      buy_price: "BUY PRICE",
      visibility: "VISIBILITY",
      volume: "VOLUME",
      stick_sell: "STICK SELL",
      stick_volume: "STICK VOLUME",
      stick_price: "STICK PRICE",
    })
  }),
  selected: fromJS({
    role: ['SFF', 'CFF', 'LRA', 'DS'],
    fields: ["handling", "availability", "stocks", "price", "buy_price", "visibility", "volume", "stick_sell", "stick_volume", "stick_price"],
    products: []
  }),

});

const getDataRequest = (state = INITIAL_STATE, action) => state;

const getDataSuccess = (state = INITIAL_STATE, { data }) => state.set('data', fromJS(data));

const getDataError = (state = INITIAL_STATE, action) => state;

const selectFilter = (state = INITIAL_STATE, { key, value }) => state.updateIn(['selected', key], array => array.push(value));

const unselectFilter = (state = INITIAL_STATE, { key, value }) =>
  state.updateIn(['selected', key], array => {
    let index = array.indexOf(value);
    if (index !== -1) return array.delete(index);
    return array;
  });

const selectTmo = (state = INITIAL_STATE, { value }) => {
  let values = state.get('data').get('products').filter(b => b.get('brand_owner') === value).map(p => p.get('id')).filter(p => !state.get('selected').get('products').contains(p)).toArray();
  return state
    .updateIn(['selected', 'products'], array => array.concat(values));
};

const unselectTmo = (state = INITIAL_STATE, { value }) => {
  let values = state.get('data').get('products').filter(b => b.get('brand_owner') === value).map(p => p.get('id')).toArray();
  return state.updateIn(['selected', 'products'], array => {
    values.forEach(p => {
      let index = array.indexOf(p);
      if (index !== -1) array = array.delete(index);
    });
    return array;
  });
};

const downloadRequest = (state = INITIAL_STATE, action) => state;

const downloadSuccess = (state = INITIAL_STATE, action) => state;

const downloadError = (state = INITIAL_STATE, action) => state;

const HANDLERS = {
  [Types.GET_DATA_REQUEST]: getDataRequest,
  [Types.GET_DATA_SUCCESS]: getDataSuccess,
  [Types.GET_DATA_ERROR]: getDataError,
  [Types.SELECT_FILTER]: selectFilter,
  [Types.UNSELECT_FILTER]: unselectFilter,
  [Types.SELECT_TMO]: selectTmo,
  [Types.UNSELECT_TMO]: unselectTmo,
  [Types.DOWNLOAD_REQUEST]: downloadRequest,
  [Types.DOWNLOAD_SUCCESS]: downloadSuccess,
  [Types.DOWNLOAD_ERROR]: downloadError
};

export const Actions = {...Types, ...Creators};
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
